zbzx.vue<template>
  <div class="tzhh">
    <div class="header">
      <div class="h1">投资黄骅</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item to="/tzhh" >投资黄骅</el-breadcrumb-item>
        <el-breadcrumb-item>创始人说</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="flex" style="margin-top: 100px">
            <div  style="width: 337px;height: 202px"><el-image :src="founderlist[number].image"></el-image></div>
            <div class="flex-1"  style="padding: 20px 50px">
              <div style="font-size: 16px;font-weight: bolder"> {{founderlist[number].name}}</div>
              <div style="margin: 25px 0">{{founderlist[number].titleSub}}</div>
              <div style="color: #747474">{{founderlist[number].content}}</div>
            </div>
      </div>
      <div style="overflow: hidden;margin: 80px 0">
        <div v-for="(li,index) in founderlist"  @click="goxq(index)" :key="index" class="people">
          <div style="width: 275px;height: 208px"><el-image :src="li.image"></el-image></div>
          <div class="bg">
            <div style="font-weight: bolder">{{li.name}}</div>
            <div style="font-size: 12px" class="text-overflow">{{li.titleSub}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {foundersaidindex} from "../../request/moudle/tzhh";

export default {
  name: "index",
  data(){
    return {
      datas:'',
      number:0,
      founderlist:[]
    }
  },
  mounted() {
    this.loadconfig()
  },
  methods:{
    loadconfig(){
      this.$api.tzhh.foundersaidindex().then((res)=>{
        this.founderlist=res.data.founderlist
      })
    },
    goxq(index){
      this.number=index
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  background: #fff;
  width: 1200px;
  margin: 20px auto;
  overflow: hidden;
}
.tzhh {
  background: #fff;
  .header {
    height: 220px;
    background: url("../../assets/banner/tzhh.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 90px;
    color: #fff;
    .h1 {
      font-size: 42px;
      font-weight: 500;
      margin-top: 50px;
      margin-left: 350px;
    }
  }
  .people{
    width: 275px;
    height: 208px;
    float: left;
    margin: 8px;
    position: relative;
    cursor: pointer;
    .bg{
      height: 40px;
      background: rgba(255,255,255,0.4);
      color: #fff;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 5px;
    }
  }
}
</style>